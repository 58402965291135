import { createContext, useContext, type ReactNode } from "react"

interface TrackingContextType {
  trackingStarted: boolean
}

interface TrackingProviderProps {
  children: ReactNode
  trackingStarted?: boolean
}

const TrackingContext = createContext<TrackingContextType | undefined>(undefined)

export function TrackingProvider({ children, trackingStarted = false }: TrackingProviderProps) {
  return <TrackingContext.Provider value={{ trackingStarted }}>{children}</TrackingContext.Provider>
}

export function useTrackingStarted(): boolean {
  const context = useContext(TrackingContext)
  if (context === undefined) {
    throw new Error("useTrackingStarted must be used within a TrackingProvider")
  }
  return context.trackingStarted
}
