import { MainLegalFooter } from "@intergamma/legal-footer"
import { Suspense } from "react"
import { withTranslation } from "react-i18next"

const LegalFooter = withTranslation("ig-legal-footer")(MainLegalFooter)

export function Footer() {
  return (
    <footer>
      <Suspense fallback={null}>
        <LegalFooter withSocials={false} withAppPromotion={false} />
      </Suspense>
    </footer>
  )
}
