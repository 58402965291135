import { IntergammaToastContainer } from "@intergamma/global-styling"
import { useCartQuery } from "api/cart"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useCurrentCustomerQuery } from "api/current-customer"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { TrackingProvider } from "context/tracking"
import { lazy } from "react"

const MultipartPayment = lazy(() =>
  import(/* webpackChunkName: "multipart-payment" */ "features/multipart-payment/components/MultipartPayment").then(
    (module) => ({
      default: module.MultipartPayment,
    })
  )
)

export function MultipartPaymentPage() {
  const checkoutPageQuery = useCheckoutPageQuery()
  const cartQuery = useCartQuery()
  const currentCustomerQuery = useCurrentCustomerQuery()

  if (!checkoutPageQuery.isSuccess || !cartQuery.isSuccess || !currentCustomerQuery.isSuccess) {
    return null
  }

  return (
    <Page moduleName="checkout">
      <Header variant="simplified" />
      <IntergammaToastContainer />
      <Main>
        <TrackingProvider trackingStarted={false}>
          <MultipartPayment />
        </TrackingProvider>
      </Main>
      <Footer />
    </Page>
  )
}
