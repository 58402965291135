import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { Button } from "@intergamma/ui/button"
import { WarningIcon } from "@intergamma/ui/icons/WarningIcon"
import { useMopinion } from "features/mopinion/hooks"
import { log } from "lib/datadog-logging"
import { useEffect } from "react"
import type { FallbackProps } from "react-error-boundary"
import { ErrorBoundary } from "react-error-boundary"
import { Trans, useTranslation } from "react-i18next"

type GenericErrorBoundaryProps = {
  children?: React.ReactNode
}

export function GenericErrorBoundary(props: GenericErrorBoundaryProps) {
  return <ErrorBoundary FallbackComponent={FallbackComponent}>{props.children}</ErrorBoundary>
}

function FallbackComponent(props: FallbackProps) {
  const { t } = useTranslation("checkout-common")
  const mopinion = useMopinion()

  useEffect(() => {
    dispatchAdobeEvent({ type: "checkout_error", data: { user_message_type: "checkout_unhandled_error" } })
    log.error("[checkout] GenericErrorBoundary", {}, props.error)
  }, [props.error])

  return (
    <div className="flex flex-col items-center gap-4 px-4 py-8 text-center">
      <WarningIcon className="text-[#cd4926]" />
      <h1 className="text-200/6 font-bold antialiased">{t("GenericErrorTitle")}</h1>
      <p>{t("GenericErrorMessage")}</p>
      <Button onClick={props.resetErrorBoundary}>{t("GenericErrorReloadPage")}</Button>
      {mopinion.loaded && (
        <p>
          <Trans t={t} i18nKey="GenericErrorReceiveFeedback">
            Als dit het probleem niet verhelpt, ontvangen we graag je
            <button className="underline" role="button" tabIndex={0} onClick={mopinion.openModal}>
              feedback
            </button>
          </Trans>
        </p>
      )}
    </div>
  )
}
