import { getHeaders } from "@intergamma/account"
import axios from "axios"
import { formulaConfig } from "config/formula"

export const api = axios.create({ baseURL: formulaConfig.CHECKOUT_URL, withCredentials: true })
export const myAccountApi = axios.create({ baseURL: formulaConfig.MYACCOUNT_URL, withCredentials: true })

api.interceptors.request.use(async (config) => {
  const { Authorization } = await getHeaders()
  config.headers.Authorization = Authorization

  return config
})

myAccountApi.interceptors.request.use(async (config) => {
  const { Authorization } = await getHeaders()
  config.headers.Authorization = Authorization

  return config
})
